@import "../../scss/var.scss";

.CaseStudies {
    .container.case-studies {
        padding-bottom: 80px;

        .item {
            .inner {
                position: relative;
                height: 100%;
                width: 100%;

                &:before {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }
            }

            &>div {
                box-sizing: border-box;
                cursor: pointer;
                position: relative;

                .img {
                    height: 400px;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    opacity: 1;
                    transition: opacity 0.25s ease;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;

                    &:before {
                        content: '';
                        background: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transition: opacity 0.2s ease-out;
                    }
                }

                h2 {
                    font-family: $heading-font-family;
                    font-weight: $heading-font-weight;
                    font-size: 21px;
                    line-height: 1.35;
                    margin-bottom: 0;
                    padding: 30px;
                }

                .ctn {
                    position: absolute;
                    top: 0;
                    width: 0;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .BasicButton {
                    transition: all 0.25s ease;
                }

                &:hover {
                    .img:before {
                        opacity: 0.5;
                    }

                    .BasicButton {
                        box-shadow: 0px 0px 0px 4px black inset;
                        transition: all 0.25s ease;
                    }
                }
            }
        }
    }
}