$base-path: 'assets/fonts/';

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a7b11");

@font-face {font-family: 'TTCommons-Bold'; src: url('#{$base-path}/3A7B11_0_0.eot');src: url('#{$base-path}/3A7B11_0_0.eot?#iefix') format('embedded-opentype'),url('#{$base-path}/3A7B11_0_0.woff2') format('woff2'),url('#{$base-path}/3A7B11_0_0.woff') format('woff'),url('#{$base-path}/3A7B11_0_0.ttf') format('truetype');}

@font-face {
    font-family: 'Apercu-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Apercu-Light'),
    local('Apercu-Light'),
    url('#{$base-path}/Apercu-Light.woff') format('woff');
}

@font-face {
    font-family: 'Apercu-Regular';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Apercu-Regular'),
    local('Apercu-Regular'),
    url('#{$base-path}/Apercu-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Apercu-Medium';
    font-style: medium;
    font-weight: 400;
    font-display: swap;
    src: local('Apercu-Medium'),
    local('Apercu-Medium'),
    url('#{$base-path}/Apercu-Medium.woff') format('woff');
}