.Routes {
    -webkit-backface-visibility: hidden;
}

.Routes>div {
    position: relative;
    -webkit-backface-visibility: hidden;
}

.Routes>div.enter {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translate3d(0,0,0);
}