@import "../../scss/var.scss";

.Home {
    position: relative;
    width: 100%;

    .ctn {
        position: relative;
        min-height: 100vh;
        background: $primary;

    }

    .allSections {
        @media screen and (max-width: map-get($grid-breakpoints, md)) {
            visibility: hidden;
            transition: height 0.25s linear;
        }
    }
}