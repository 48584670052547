@import "../../scss/var.scss";

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.Loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    // background: linear-gradient(to bottom, rgba(119, 172, 239, 1) 67%, rgba(73, 131, 228, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#77acef', endColorstr='#4983e4', GradientType=0);
    /* IE6-9 */



    &>div.cubeloader {
        position: fixed;
        bottom: 75px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        color: white;
        font-weight: 300;
        font-family: $font-family-regular;
        letter-spacing: 2px;

        div:first-child {
            margin-bottom: 10px;
            animation: rotating 10s linear infinite;
        }
    }

    &>div.loader-text {
        position: relative;
        text-align: center;


        .loader-text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            > div {
                overflow: hidden;
                width: 100%;
                // display: inline-flex;
                flex-direction: row;
                flex-wrap: nowrap;
                background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60' preserveAspectRatio='none'><path fill='%23fff' d='M0 0h60v60H0z'/></svg>") no-repeat top left;
                background-clip: text;
                background-size: 100% 50%;
                color: transparent;
                opacity: 1;
            }
        }

        .loader-line {
            font-family: $heading-font-family;
            font-weight: $heading-font-weight;
            font-size: 42px;
            line-height: 52px;
            color: #fff;
            display: block;
            opacity: 0.5;
            padding: 0 30px;
        }

    }

    .header {
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        padding-top: 60px;
        padding-bottom: 60px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        background-color: white;
        transition: background-color 0.5s ease-out;
        z-index: 1;
        visibility: hidden;

        h1 {
            line-height: 0;
            font-size: 0;
            visibility: visible;
            position: relative;

            img {
                width: 80px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }


    @media screen and (max-width: 767px) {
        &>div.loader-text {
            .loader-line {
                font-size: 30px;
                line-height: 1.15;
                // text-overflow: clip;
                // white-space: nowrap;
            }
        }

        .header {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}