@import "../../scss/var.scss";

.Services {
  .services--item {
    color: #ffffff;
    padding: 45px 0 0 0;
    overflow: hidden;

    a:not(.BasicButton) {
      border-bottom: solid 1px $white;
      color: $white;
    }
  }

  .services--platform {
    max-width: 200%;
    margin-bottom: -30px;
    width: 200%;

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      max-width: 230%;
      margin-right: -100%;
      margin-bottom: 0;
      width: 230%;
    }

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      max-width: 150%;
      margin-right: -40%;
      margin-bottom: -30px;
      width: 150%;
    }
  }
}
