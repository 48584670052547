@import "../../scss/var.scss";

.Header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    padding: 60px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: white;
    transition: background-color 0.3s ease-out, padding 0.3s ease-out;
    z-index: 1;
    visibility: hidden;

    body.home & {
        background-color: $primary;
        transition: background-color 0.3s ease-out, padding 0.3s ease-out;

        > .container {
            max-width: $max-fluid-container-width !important;
            padding-left: 30px;
            padding-right: 30px;

            @media (min-width: map-get($grid-breakpoints, lg)) {
                padding-left: 75px;
                padding-right: 75px;
            }
        }
    }

    .brand {
        line-height: 0;
        font-size: 0;
        visibility: visible;
        position: relative;

        img {
            width: 80px;
            position: absolute;
            top: 0;
            left: 0;

            &.default {
                visibility: hidden;
                opacity: 0;
                transition: all 0.25s ease-out;

                body.home & {
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.25s ease-out;
                }
            }

            &.black {
                transition: all 0.25s ease-out;

                body.home & {
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.25s ease-out;
                }
            }
        }
    }

    svg {
        width: 30px;
        height: 30px;

        circle {
            fill: black;
        }

        body.home & {
            circle {
                fill: white;
            }
        }

    }

    .buttons {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        visibility: visible;
    }

    .prev-home {
        font-family: $font-family-regular;
        border: none;
        background: none;
        text-transform: uppercase;
        margin-right: 10px;
        cursor: pointer;
    }

    .row--header-nav {
        // padding: 0 60px;
    }

    // .row {
    //     margin-right: 0;
    //     margin-left: 0;

    //     & > .col-6 {
    //         padding-left: 0;
    //         padding-right: 0;
    //     }
    // }

    .toggle-menu {
        background: none;
        border: 0;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        padding: 0 10px;
        text-transform: uppercase;
        width: 50px;

        &:focus {
            outline: 0;
        }
    }

    &.affix {
        background-color: #fff;
        visibility: visible;
        padding-top: 20px;
        padding-bottom: 20px;

        .top-menu {
            padding-top: 20px;
        }
    }

    @media (max-width: map-get($grid-breakpoints, md) - 1) {
        padding: 30px 0;
    }
}