@import "../../scss/var.scss";

.CaseStudy {
    h1 {
      margin-top: 20px;
      margin-bottom: 45px;
      max-width: 380px;
    }

    h2 {
      font-family: $font-family-medium;
      font-weight: bold;
      margin-bottom: 0.25em !important;
    }

    .content__image {
      margin-top: 0 !important;
      padding-top: 0 !important;

      img {
        max-width: 100% !important;
        width: 320px;
      }
    }

    [count] .content__image{
      padding-left: 10px;
    }

    [count] .content__image:first-child {
      padding-left: 0;
    }

    .p-wrapper {
      font-size: 20px;
    }

    h3.p-wrapper {
      font-size: 26px;
      margin-bottom: 12px;
    }
    .ie-flex-fix {
      flex: 1 1 auto;
    }
}
