@import "../../scss/var.scss";

.About {
    .testimonial{

      &__person {
        font-size: 0.9em;
        order: 2;
      }

      &__quote {
        order: 1;
      }

      &__author {
        font-weight: bold;
      }

      @media screen and (min-width: map-get($grid-breakpoints, md)) {
        .testimonial__person {
          order: 1;
        }

        .testimonial__quote {
          order: 2;
        }
      }
    }
    .team {
        img {
            max-width: 250px;
            height: auto;
            width: 100%;
        }
    }
}