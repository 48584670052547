.BasicButton {
    display: inline-block;
    background: none;
    // border: 2px solid white;
    box-shadow: 0px 0px 0px 2.5px white inset;
    border: none;
    // border-radius: 35px;
    padding: 8px 24px;
    // margin: 2px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: box-shadow 0.2s ease-out;

    span {
        font-size: 12px;
        letter-spacing: 1px;
        color: white;
    }

    &:hover {
        box-shadow: 0px 0px 0px 3.5px white inset !important;
    }

    &.btn-lg {
        padding: 4px 18px 7px 18px;
        min-width: 160px;

        span {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &.black {
        box-shadow: 0px 0px 0px 1px #333 inset;

        span {
            color: #333;
        }

        &:hover {
            box-shadow: 0px 0px 0px 2.5px #333 inset !important;
        }
    }

    &.navy {
        box-shadow: 0px 0px 0px 1px #333 inset;

        span {
            color: #012941;
            font-weight: bold;
        }

        &:hover {
            box-shadow: 0px 0px 0px 2.5px #333 inset !important;
        }
    }

    &.white {
        background: #fff;
        box-shadow: none;

        span {
            color: #000;
        }

        &:hover {
            box-shadow: 0px 0px 0px 2.5px #fff inset !important;
        }
    }

    @media screen and (max-width: 767px) {

        span {
            font-size: 12px;

        }
    }
}