@import '../../scss/var.scss';

.HomeSection {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  visibility: hidden;
  display: none;
  padding-top: 140px;
  max-width: 1440px;
  margin: 0 auto;

  &:first-child {
    position: relative;
  }

  & .left {
    display: flex;
    flex-direction: column;
    visibility: visible ;
    pointer-events: none;

    .back-btn {
      pointer-events: all;
      background: none;
      border: none;
      color: $white;
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 40px;
      text-align: left;
      cursor: pointer;
      font-weight: bold;
      position: relative;
      padding: 5px 0 5px 0;

      span {
        margin-right: 20px;
        transition: margin-right 0.25s ease;
        text-indent: 0;
        display: inline-block;
        width: 0;
      }

      &::before {
        content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12'><path fill='%23fff' fill-rule='nonzero' d='M0 10.585L4.585 6 0 1.415 1.415 0l6 6-6 6z'/></svg>");
        display: block;
        position: absolute;
        left: -20px;
        top: 7px;
        height: 12px;
        width: 8px;
        transform: rotate(180deg);
        transition: transform 0.15s ease-out;
      }

      &:hover {
        span {
          transition: text-indent 0.25s ease;
          text-indent: -15px;
        }

        &::before {
          transform: translate(-5px, 0) rotate(180deg);
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }

  h1, .back-btn, div.paragraph, .button {
    pointer-events: all;
  }

  h1 {
    margin-bottom: 22px;
    max-width: 390px;
  }

  div.paragraph {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 32px;
    width: 70%;
    max-width: 375px;

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $white;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &#home0 {
    &::before,
    &::after {
      content: "Move the hub to select your project’s starting point.";
      background: #fff;
      text-align: center;
      font-size: 14px;
      font-family: $font-family-medium;
      color: $primary;
      position: absolute;
      top: 130px;
      right: 40px;
      display: none;
      height: 64px;
      width: 232px;
      visibility: visible;
      padding: 12px;
      line-height: 1.3;
      border-radius: 44px;
    }

    &::after {
      left: 0;
      margin: auto;
      position: fixed;
      bottom: 0;
      right: 0;
      top: auto;
      display: block;
      border-radius: 0;
      height: 40px;
      width: 100%;
      box-shadow: 0px 0 20px rgba(14, 44, 67, 0.5);

      @media (max-width: 380px) {
        font-size: 3.7vw;
      }
    }

    .button--mobile,
    .paragraph__full {
      display: none;
    }

    .button {
      max-width: 200px;
    }

    @media (max-width: map-get($grid-breakpoints, sm)-1) {
      padding-top: 94px;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      &::after {
        display: none;
      }

      &::before {
        display: block;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    &#home0 {
      &::before {
        right: 90px;
      }
    }
  }

  > .container-fluid {
    padding-left: 30px;
    padding-right: 30px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-left: 75px;
        padding-right: 75px;
    }
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    padding-top: 150px;

    h1 {
      white-space: pre-line;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 10px;
      z-index: 0;
    }

    h2 {
      margin-bottom: 10px;
    }

    div.paragraph {
      font-size: 16px;
      line-height: 1.8;
    }

    &:first-child {
      h1 {
        white-space: pre-line;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 120px;

    & .left {
      div.paragraph {
        width: 100%;
        max-width: 370px;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 370px) {
    & .left {
      div.paragraph {
        max-width: 100%;
      }
    }
  }
}
