.container-fluid {
    max-width: $max-fluid-container-width;
}

a {
    color: $primary;

    &:hover {
        text-decoration: none;
    }

    .text-secondary & {
        color: $secondary;
    }
}

a:not(.BasicButton):not(.nav-link) {
    text-decoration: none;
    border-bottom: solid 1px $primary;
    transition: border 0.1s ease-out;

    &.text-white,
    .text-white & {
        border-bottom-color: #fff;
    }

    .text-muted &,
    &.text-muted {
        border-bottom-color: #6d757e;
    }

    .text-dark &,
    &.text-dark {
        border-bottom-color: #343a40;
    }

    .text-secondary & {
        border-bottom-color: $secondary;
    }

    &:hover {
        text-decoration: none;
        border-bottom-color: rgba(255, 255, 255, 0);
    }
}

.bg-black {
    background: $black;
}

.bg-primary {
    background: $primary;
    color: $white;
}

.bg-white {
    background: $white;
}

.p-relative {
    position: relative;
}

img.mw-100 {
    height: auto;
}

.mh-500 {
    min-height: 500px;
    display: flex;
    align-items: center;
}

.mw-50 {
    max-width: 50%;
}

.mw-70 {
    max-width: 70%;
}

@media (min-width: map-get($grid-breakpoints, md)) {
    .mw-md-50 {
        max-width: 50%;
    }

    .mw-md-70 {
        max-width: 70%;
    }
}