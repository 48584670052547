.form-control {
    display: block;
    width: 100%;
    padding: 16px;
    // border-radius: 32px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $secondary;
    border-radius: 0;
    color: $secondary;
    font-family: $font-family-regular;
    transition: border-color .15s ease-in-out, color .15s ease-in-out;

    &:focus {
        border-color: $text-color;
        color: $text-color;
        outline: 0;
    }

    + .form-control {
        margin-top: 12px;
    }

}

.form-control {
    &.phone {
        border: 0; height: 0; width: 0; margin: 0; padding: 0; overflow: hidden;
    }
}

textarea {
    height: 150px;
}

.form-footer .BasicButton {
    &:hover {
        box-shadow: 0px 0px 0px 2px #333 inset;
    }
}

.alert {
    border: solid 1px $success;
    color: $success;
    background: lighten($success, 43%);
    // border-radius: 16px;
    padding: 20px;
    margin-top: 30px;
    font-size: 16px;
    opacity: 0;
    display: block;
    transition: opacity 0.15s ease-out;
}