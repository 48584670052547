@import "../../scss/var.scss";

.WebglBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $primary;

    @media screen and (max-width: map-get($grid-breakpoints, md)) {
        position: fixed;
        top: 0;

        canvas { display: block; opacity: 1; }
    }
}