@import "../../scss/var.scss";

.MainMenu {
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    visibility: visible;

    // .main-menu__padding {
    //     padding: 0 15px;
    // }

    // .brand__padding {
    //     margin-left: 15px;
    // }

    .top-menu {
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        padding-top: 60px;
        padding-bottom: 60px;
        // padding-left: 15px;
        // padding-right: 15px;
        transition: padding 0.1s ease-out;

        img {
            width: 80px;
        }

        .toggle-menu {
            svg circle {
                fill: $text-color;
            }
        }
    }

    .menu-inside {
        top: 0;
        left: 0;
        overflow: hidden;
        // padding-left: 15px;
        // padding-right: 15px;

        &>div {

            // & > .row > .col-12 {
            //     padding-left: 0
            // }

            ul {
                padding: 0 30px 0 0;
                margin: 0;

                li {
                    display: block;
                    padding: 5px 0;

                    button,
                    a {
                        text-decoration: none;
                        color: $secondary;
                        font-size: 36px;
                        font-family: $heading-font-family;
                        font-weight: $heading-font-weight;
                        line-height: 1;
                        opacity: 1;

                        &:hover {
                            opacity: 0.5;
                        }

                        &:focus {
                            outline: 0;
                        }
                    }
                }
            }

            .content {
                overflow: hidden;
            }
        }
    }

    @media (max-width: map-get($grid-breakpoints, md) - 1) {
        .top-menu {
            padding-top: 30px;
        }

        .menu-inside>div {
            padding-top: 84px;
            padding-bottom: 84px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: 100%;
            padding-bottom: 90px;
            overflow: hidden;

            .content {
                padding-top: 50px;

                .content-wrapper {
                    padding-left: 0;
                    padding-right: 0;

                    h3,
                    p,
                    a {
                        font-size: 12px;
                        line-height: 15px;
                    }

                    ul li {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }


                    h3 {
                        margin-bottom: 15px;
                    }

                    .split {

                        div:first-child {
                            padding-right: 15px;
                        }

                        div:last-child {
                            padding-left: 15px;

                            a {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            ul li {

                button,
                a {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }
    }
}