h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p, ul {
    margin-bottom: 32px;
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
    line-height: 1;

    @media (min-width: map-get($grid-breakpoints, lg)) {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.h1,
h1 {
    font-size: 48px;
}

.h2,
h2 {
    font-size: 37px;
}

.h3,
h3 {
    font-size: 31px;

}

h4,
.h4 {
    font-size: 24px;
}

h5,
.h5 {
    font-size: 21px;
}

h6,
.h6 {
    font-size: 17px;
    text-transform: uppercase;
}

.strong,
strong {
    font-family: $font-family-medium;
    font-weight: 700;
}

.p {
    font-size: 17px;
}

.text-lg {
    font-size: 18px;
}

.text-xl {
    font-size: 24px;
    line-height: 1.4em;
}

.lead {
    font-size: 17px !important;
    line-height: 1.8 !important;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.font-weight-light {
    font-family: $font-family-Light;
}

.font-0 {
    font-size: 0;
}

.text-dark {
    color: $text-color;
}

.text-white {
    color: $white;
}

.text-primary {
    color: $primary;
}

.text-secondary {
    color: $secondary;
}

.text-muted {
    color: #ccc;
}

@media (min-width: map-get($grid-breakpoints, md)) {
    .text-md-right {
        text-align: right;
    }

    .text-md-left {
        text-align: left;
    }
}